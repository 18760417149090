import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const PressAndMedia = () => {
  return (
    <section className="press-media">
      <Helmet>
        <title>Press and Media - Dr Vivek Kakkad | IVF Superspecialist</title>
      </Helmet>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 col-sm-12">
              <h1>Press and Media</h1>
              <h5>
                Stay Informed. Explore the Latest News and Media Coverage.
              </h5>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="about-video"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <div className="video-box">
                <video controls muted loop>
                  <source
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/Dr-Vivek-Kakkad-Press-Video.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className="col-xl-6 col-sm-12">
              <div className="about-details">
                <div>
                  <h2>Interview with Hello Saurashtra News</h2>
                  <p className="tagline">
                    Exclusive Interview of Dr. Vivek Kakkad and Dr. Human Fatemi
                    (Group Medical Director at ART Fertility Clinic) with Hello
                    Saurashtra news at ART Fertility Clinic, Ahmedabad
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row iframe-videos">
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="iframe-box">
                <iframe
                  className="youtube-video-iframe"
                  src="https://www.youtube.com/embed/FG1DT2eQLJQ"
                  title="What is Testicular Sperm Aspiration (TESA)"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <h5>
                  What is Testicular Sperm Aspiration (TESA) and how is it done?
                </h5>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="iframe-box">
                <iframe
                  className="youtube-video-iframe"
                  src="https://www.youtube.com/embed/MH3tOYx5VWg"
                  title="Infertility Unfiltered - When should you consult an expert"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
                <h5>
                  Infertility Unfiltered - When should you consult an expert?
                </h5>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-sm-12">
              <div className="iframe-box">
                <video controls muted loop>
                  <source
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/Vivek-Kakkad-Appreciation-Video.mp4"
                    type="video/mp4"
                  />
                </video>
                <h5>
                  Exclusive Interview of Dr. Vivek Kakkad and Dr. Human Fatemi
                  with DD News
                </h5>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="img-slider"
      >
        <div className="container">
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.3 }}
            variants={{
              visible: { opacity: 1 },
              hidden: { opacity: 0 },
            }}
            className="row justify-content-center"
          >
            <div className="col-xl-10 col-sm-12">
              <h2>
                Dr Vivek Kakkad meeting and attending global conferences as well
                as meeting industry leader doctors during his tenures
              </h2>
            </div>

            <div className="col-xl-12 col-lg-12 col-sm-12">
              <Swiper
                spaceBetween={20}
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                  },
                  400: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1000: {
                    slidesPerView: 4,
                  },
                }}
                modules={[Navigation]}
                navigation
                className="achievements-slider"
              >
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/art-fertility-clinic-group-photo.png"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/1.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/3.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/4.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/6.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/9.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/10.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/12.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/13.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/14.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src="https://storage.googleapis.com/cdn-dr-vivek-kakkad-website/achievements/15.webp"
                    className="slider-image"
                    alt="Vivek Kakkad"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default PressAndMedia;
